<template>
    <div class="container">
        <div class="left">
            <div class="toggle" @click="showOrder = !showOrder">
                {{ showOrder ? 'Hide order summary' : 'Show order summary' }}
                <i :class="showOrder ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
            <div class="back-home" @click="toHome">Back to home page</div>
            <div class="breadcrumb-box">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>Cart</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: fullPath }">Information</el-breadcrumb-item>
                    <el-breadcrumb-item>Payment</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="payment">
                <div class="label">Digital Currency</div>
                <div class="cur-pay">
                    <div class="desc">Digital currency Express Checkout</div>
                    <img class="img" src="@/assets/images/index/pay-img.png" />
                </div>
            </div>
            <el-form :model="form" ref="form" class="form">
                <div class="label">Shipping Address</div>
                <el-row :gutter="10">
                    <template v-if="showAddress">
                        <el-col :xs="24" :sm="12" :md="12">
                            <el-form-item label="First name" prop="user_name_first">
                                <el-input v-model="form.user_name_first" disabled placeholder=""></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="12">
                            <el-form-item label="Last name" prop="user_name_last">
                                <el-input v-model="form.user_name_last" disabled placeholder=""></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="Address" prop="address">
                                <el-input v-model="form.address" disabled placeholder=""></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="Apartment, suite, etc. (optional)" prop="apartment">
                                <el-input v-model="form.apartment" disabled placeholder=""></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8">
                            <el-form-item label="Country" prop="country">
                                <el-input v-model="form.country" disabled placeholder=""></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8">
                            <el-form-item label="State" prop="state">
                                <el-input v-model="form.state" disabled placeholder=""></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8">
                            <el-form-item label="Postal code" prop="postal_code">
                                <el-input v-model="form.postal_code" disabled placeholder=""></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="City" prop="city">
                                <el-input v-model="form.city" disabled placeholder=""></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="Phone" prop="phone">
                                <el-input v-model="form.phone" disabled placeholder=""></el-input>
                            </el-form-item>
                        </el-col>
                    </template>
                    <div v-else class="add-btn" @click="add">Add An Address</div>
                    <el-col :span="24">
                        <el-form-item>
                            <div class="operate">
                                <div class="back" @click="back">
                                    <i class="el-icon-arrow-left el-icon--left"></i>
                                    Back to change
                                </div>
                                <div class="submit-btn" @click="pay">Continue to Shipping</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div v-show="showOrder" class="right">
            <div class="goods-list">
                <div v-for="(item,index) in goodsList" :key="index" class="goods-item">
                    <el-badge type="info" :value="item.num" :max="99" class="img-box">
                        <img class="img" :src="item.picture" />
                    </el-badge>
                    <div class="right-info">
                        <div class="info-item1">
                            <div class="name">{{item.good_name}}</div>
                            <div class="tags">
                                <el-tag v-for="(val,key) in JSON.parse(item.sku)" :key="key" type="danger" size="mini" class="tag">{{val}}</el-tag>
                            </div>
                        </div>
                        <div class="info-item2">
                            <!-- <i class="el-icon-delete"></i> -->
                            <div class="price">{{$currencySymbol}}{{parseFloat(item.per_price)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divide-line"></div>
            <div class="cal">
                <div class="cal-item">
                    <div class="label">Subtotal:</div>
                    <div class="value">{{$currencySymbol}}{{totalPrice}}</div>
                </div>
                <div class="cal-item">
                    <div class="label">Coupon savings:</div>
                    <div class="value">{{$currencySymbol}}0</div>
                </div>
                <div class="cal-item">
                    <div class="label">Shipping charges:</div>
                    <div class="value">{{$currencySymbol}}0</div>
                </div>
            </div>
            <div class="divide-line"></div>
            <div class="total-price">
                <div class="label">Total Price:</div>
                <div class="value">{{$currencySymbol}}{{totalPrice}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                type: '',
                cur_goods: {},
                showAddress: true,
                address_id: '',
                form: {
                    user_name_first: '',
                    user_name_last: '',
                    address: '',
                    apartment: '',
                    country: '',
                    state: '',
                    postal_code: '',
                    city: '',
                    phone: ''
                },
                showOrder: true
            }
        },
        created() {
            this.getAddress()
            
            this.type = this.$route.query.type
            if(this.type == 'unit') {
                this.cur_goods = JSON.parse(this.$route.query.goods)
            } else {
                this.$store.dispatch('getCartList')
            }
        },
        computed: {
            fullPath() {
                return this.$route.fullPath
            },
            
            goodsList() {
                if(this.type == 'unit') {
                    return [JSON.parse(this.$route.query.goods)]
                } else {
                    return this.$store.state.cartList
                }
            },
            
            totalPrice() {
                let total_price = this.goodsList.reduce((accumulator, currentValue) => {
                    return accumulator + parseFloat(currentValue.per_price) * currentValue.num
                },0)
                
                return total_price.toFixed(2)
            }
        },
        methods: {
            getAddress() {
                this.$api.getAddress({},true).then(res => {
                    let default_address = res.data[0]
                    if(default_address) {
                        this.showAddress = true
                        Object.keys(this.form).forEach(key => {
                             this.form[key] = default_address[key]
                        })
                        this.address_id = default_address.id
                    } else {
                        this.showAddress = false
                    }
                })
            },
            
            add() {
            	this.$router.push({
            	    path: '/AddAddress'
            	})
            },
            
            pay() {
                if(!this.showAddress) {
                    this.$message({
                        message: 'Please add an address first',
                        type: 'warning',
                        duration: 1500,
                        onClose: () => {
                            this.add()
                        }
                    })
                    return
                } else {
                    if(this.type == 'unit') {
                        this.$api.buyDirect({
                            good_id: this.cur_goods.id,
                            sku: this.cur_goods.sku,
                            num: this.cur_goods.num,
                            address_id: this.address_id,
                        },true).then(res => {
                            location.href = res.data
                        })
                    } else {
                        this.$api.buyCart({
                            address_id: this.address_id,
                        },true).then(res => {
                            location.href = res.data
                        })
                    }
                }
            },
            
            toHome() {
                this.$router.push({
                    path: '/'
                })
            },
            
            back() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
            display: flex;
            width: 100%;
            min-height: 100vh;
            box-sizing: border-box;
            padding: 4rem 0;
            
            .left {
                width: 58%;
                box-sizing: border-box;
                padding-right: 4rem;
                border-right: 1px solid #e9e9e9;
                
                .toggle {
                    display: none;
                }
                
                .back-home {
                    width: 100%;
                    font-size: 2rem;
                    color: #2e9cc3;
                    word-wrap: break-word;
                    cursor: pointer;
                }
                
                .breadcrumb-box {
                    width: 100%;
                    margin: 1.5rem 0 3rem;
                }
                
                .payment {
                    width: 100%;
                    margin-bottom: 4rem;
                    
                    .label {
                        width: 100%;
                        margin-bottom: 2rem;
                        font-size: 1.8rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .cur-pay {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 11rem;
                        box-sizing: border-box;
                        border: 1px solid #d9d9d9;
                        border-radius: 5px;
                        
                        .desc {
                            position: absolute;
                            top: -.8rem;
                            left: 50%;
                            transform: translateX(-50%);
                            font-size: 1.4rem;
                            color: #333333;
                            white-space: nowrap;
                            background-color: #FFFFFF;
                        }
                        
                        .img {
                            height: 7.5rem;
                        }
                    }
                }
                
                .form {
                    width: 100%;
                    
                    .label {
                        width: 100%;
                        margin-bottom: 2rem;
                        font-size: 1.8rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    /deep/ .el-form-item {
                        margin-bottom: 2rem;
                    }
                    
                    .operate {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        margin-top: .8rem;
                        
                        .back {
                            font-size: 1.4rem;
                            color: #333333;
                            white-space: nowrap;
                            cursor: pointer;
                        }
                        
                        .submit-btn {
                            min-width: 12rem;
                            height: 6rem;
                            line-height: 6rem;
                            box-sizing: border-box;
                            padding: 0 2.5rem;
                            border-radius: 4px;
                            font-size: 1.5rem;
                            color: #FFFFFF;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #000000;
                            cursor: pointer;
                        }
                    }
                    
                    .add-btn {
                        display: inline-block;
                        min-width: 9rem;
                        height: 4.5rem;
                        line-height: 4.5rem;
                        box-sizing: border-box;
                        padding: 0 5rem;
                        margin-bottom: 4rem;
                        border-radius: 4px;
                        font-size: 1.5rem;
                        color: #FFFFFF;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #333333;
                        cursor: pointer;
                    }
                }
            }
            
            .right {
                display: block !important;
                width: 42%;
                box-sizing: border-box;
                padding-left: 4rem;
                
                .goods-list {
                    width: 100%;
                    
                    .goods-item {
                        display: flex;
                        width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 1.5rem;
                        }
                        
                        .img-box {
                            margin-right: 1.5rem;
                            
                            .img {
                                display: block;
                                min-width: 7rem;
                                max-width: 7rem;
                                height: 7rem;
                            }
                        }
                        
                        .right-info {
                            display: flex;
                            width: 100%;
                            
                            .info-item1 {
                                width: 75%;
                                box-sizing: border-box;
                                padding-right: 1.5rem;
                                
                                .name {
                                    width: 100%;
                                    margin-bottom: .8rem;
                                    font-size: 1.4rem;
                                    color: #333333;
                                    word-wrap: break-word;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                                
                                .tags {
                                    display: flex;
                                    align-items: center;
                                    width: 100%;
                                    
                                    .tag {
                                        &:not(:last-child) {
                                            margin-right: .5rem;
                                        }
                                    }
                                }
                            }
                            
                            .info-item2 {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                width: 25%;
                                
                                .el-icon-delete {
                                    margin-bottom: .8rem;
                                    font-size: 1.6rem;
                                    color: #333333;
                                    white-space: nowrap;
                                    cursor: pointer;
                                }
                                
                                .price {
                                    max-width: 100%;
                                    padding-top: 1rem;
                                    font-size: 1.5rem;
                                    font-weight: bold;
                                    color: #000000;
                                    word-break: break-all;
                                }
                            }
                        }
                    }
                }
                
                .divide-line {
                    width: 100%;
                    height: 1px;
                    margin: 2.5rem 0;
                    background-color: #e9e9e9;
                }
                
                .cal {
                    width: 100%;
                    
                    .cal-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 1.5rem;
                        }
                        
                        .label {
                            max-width: 48%;
                            font-size: 1.4rem;
                            color: #333333;
                            word-wrap: break-word;
                        }
                        
                        .value {
                            max-width: 48%;
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: #000000;
                            word-break: break-all;
                        }
                    }
                }
                
                .total-price {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    
                    .label {
                        max-width: 48%;
                        font-size: 1.4rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .value {
                        max-width: 48%;
                        font-size: 2.4rem;
                        font-weight: bold;
                        color: #000000;
                        word-break: break-all;
                    }
                }
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            min-height: 100vh;
            box-sizing: border-box;
            padding: $h5-box-padding;
            
            .left {
                width: 100%;
                box-sizing: border-box;
                
                .toggle {
                    width: 100%;
                    margin-bottom: 2.5rem;
                    font-size: 1.4rem;
                    color: #333333;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .back-home {
                    width: 100%;
                    font-size: 1.8rem;
                    color: #2e9cc3;
                    word-wrap: break-word;
                    cursor: pointer;
                }
                
                .breadcrumb-box {
                    width: 100%;
                    margin: 1.5rem 0 2.5rem;
                }
                
                .payment {
                    width: 100%;
                    margin-bottom: 2.5rem;
                    
                    .label {
                        width: 100%;
                        margin-bottom: 2rem;
                        font-size: 1.8rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .cur-pay {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 11rem;
                        box-sizing: border-box;
                        border: 1px solid #d9d9d9;
                        border-radius: 5px;
                        
                        .desc {
                            position: absolute;
                            top: -.8rem;
                            left: 50%;
                            transform: translateX(-50%);
                            font-size: 1.4rem;
                            color: #333333;
                            white-space: nowrap;
                            background-color: #FFFFFF;
                        }
                        
                        .img {
                            height: 7.5rem;
                        }
                    }
                }
                
                .form {
                    width: 100%;
                    
                    .label {
                        width: 100%;
                        margin-bottom: 1.5rem;
                        font-size: 1.8rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    /deep/ .el-form-item {
                        margin-bottom: 1.5rem;
                    }
                    
                    .operate {
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                        width: 100%;
                        margin-top: .8rem;
                        
                        .back {
                            margin-top: .8rem;
                            font-size: 1.4rem;
                            color: #333333;
                            white-space: nowrap;
                            cursor: pointer;
                        }
                        
                        .submit-btn {
                            width: 100%;
                            height: 5.5rem;
                            line-height: 5.5rem;
                            box-sizing: border-box;
                            padding: 0 2rem;
                            border-radius: 4px;
                            font-size: 1.5rem;
                            color: #FFFFFF;
                            text-align: center;
                            white-space: nowrap;
                            background-color: #000000;
                            cursor: pointer;
                        }
                    }
                    
                    .add-btn {
                        width: 100%;
                        height: 4.5rem;
                        line-height: 4.5rem;
                        box-sizing: border-box;
                        padding: 0 2rem;
                        margin-bottom: 4rem;
                        border-radius: 4px;
                        font-size: 1.5rem;
                        color: #FFFFFF;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #333333;
                        cursor: pointer;
                    }
                }
            }
            
            .right {
                width: 100%;
                box-sizing: border-box;
                margin-bottom: 2.5rem;
                
                .goods-list {
                    width: 100%;
                    
                    .goods-item {
                        display: flex;
                        width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 1.5rem;
                        }
                        
                        .img-box {
                            margin-right: 1.5rem;
                            
                            .img {
                                display: block;
                                min-width: 7rem;
                                max-width: 7rem;
                                height: 7rem;
                            }
                        }
                        
                        .right-info {
                            display: flex;
                            width: 100%;
                            
                            .info-item1 {
                                width: 75%;
                                box-sizing: border-box;
                                padding-right: 1.5rem;
                                
                                .name {
                                    width: 100%;
                                    margin-bottom: .8rem;
                                    font-size: 1.4rem;
                                    color: #333333;
                                    word-wrap: break-word;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                                
                                .tags {
                                    display: flex;
                                    align-items: center;
                                    width: 100%;
                                    
                                    .tag {
                                        &:not(:last-child) {
                                            margin-right: .5rem;
                                        }
                                    }
                                }
                            }
                            
                            .info-item2 {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                width: 25%;
                                
                                .el-icon-delete {
                                    margin-bottom: .8rem;
                                    font-size: 1.6rem;
                                    color: #333333;
                                    white-space: nowrap;
                                    cursor: pointer;
                                }
                                
                                .price {
                                    max-width: 100%;
                                    padding-top: 1rem;
                                    font-size: 1.5rem;
                                    font-weight: bold;
                                    color: #000000;
                                    word-break: break-all;
                                }
                            }
                        }
                    }
                }
                
                .divide-line {
                    width: 100%;
                    height: 1px;
                    margin: 2.5rem 0;
                    background-color: #e9e9e9;
                }
                
                .cal {
                    width: 100%;
                    
                    .cal-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 1.5rem;
                        }
                        
                        .label {
                            max-width: 48%;
                            font-size: 1.4rem;
                            color: #333333;
                            word-wrap: break-word;
                        }
                        
                        .value {
                            max-width: 48%;
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: #000000;
                            word-break: break-all;
                        }
                    }
                }
                
                .total-price {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    
                    .label {
                        max-width: 48%;
                        font-size: 1.4rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                    
                    .value {
                        max-width: 48%;
                        font-size: 2.4rem;
                        font-weight: bold;
                        color: #000000;
                        word-break: break-all;
                    }
                }
            }
        }
    }
</style>